<template>
  <div>
    <Component
      :is="`blueprint-${entry.blueprint?.replaceAll('_', '-')}`"
      v-if="entry"
      :page="entry"
    />
    
  </div>
</template>

<script setup lang="ts">
definePageMeta({
  pageTransition: {
    name: 'page',
    mode: 'out-in',
    onBeforeEnter: () => {
      const imagePageTransition = useCookie('imagePageTransition')
      if (imagePageTransition.value) {
        imagePageTransition.value = null
        // The target page should be scrolled to the top
        if (window && window.scrollTo) {
          window.scrollTo(0, 0)
        }
      }
    },
  },

  middleware(to) {
    // check if page transition is set
    if (
      !to.meta.pageTransition ||
      typeof to.meta.pageTransition === 'boolean'
    ) {
      return
    }

    // If disable page transition is true then disable it
    const imagePageTransition = useCookie('imagePageTransition')
    if (imagePageTransition.value) {
      // Remove
      to.meta.pageTransition.name = 'page-image'
      to.meta.pageTransition.mode = 'in-out'
      return
    }

    // If window scroll position is 0
    if (window && window.scrollY === 0) {
      to.meta.pageTransition.name = 'page-overlap'
      to.meta.pageTransition.mode = 'in-out'
      return
    }

    // Ensure page transition is set to page if no special case is met
    if (to.meta.pageTransition.name !== 'page') {
      to.meta.pageTransition.name = 'page'
      to.meta.pageTransition.mode = 'out-in'
    }
  },
})

const entryInjection = inject(EntryInjectionKey)!

const entry = useIsInLivePreview() ? entryInjection : ref(unref(entryInjection))

// https://kit.novu.ch/packages/nuxt/seotamic.html
useSeotamic(entry)

await useHead({
  bodyAttrs: {
    class: useIsInLivePreview() ? 'live-preview-active' : '',
  },
})
</script>
